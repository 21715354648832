import React from "react";

function Promotion ( props )
{
    return (
        <div className="grid">
            <div className="col-12">
                <div className="card">
                    <iframe className="w-full " height={800} src="https://nganhang.unestgroup.com" frameborder="0" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    );
}

export default Promotion;
