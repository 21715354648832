import React from "react";
import axios from "axios";
import moment from "moment";
import PDFfile from "./PDFfile";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Document, Packer, Paragraph, TextRun, AlignmentType, LevelFormat, Numbering, Indent, HeadingLevel, convertInchesToTwip, Table, TableRow, TableCell, WidthType } from "docx";
import { saveAs } from "file-saver";
import { Button } from "primereact/button";
import { pdf } from "@react-pdf/renderer";

import { union } from "lodash";
import { SplitButton } from "primereact/splitbutton";
import { useMutation } from "react-query";
import { createHistory } from "../../../../service/historyAPI";
import { useSelector } from "react-redux";
export default function ExportDay({ queryDate }) {
  const token = useSelector((state) => state.user.token);
  const mergeDedupe = (arr) => {
    return [...new Set([].concat(...arr))];
  };
  const userId = useSelector((state) => state.user.userData?.id || "");
  const addHistory = useMutation(createHistory, {
    onError: (e) => {
      console.log(e);
    },
  });
  const fetchData = async () => {
    // ?fromDate=${moment().startOf("week").toISOString()}&toDate=${moment().endOf("day").toISOString()
let queryStr = `${process.env.REACT_APP_API_URL}/content?process=true&fromDate=${moment(queryDate).startOf("day").toISOString()}&toDate=${moment(queryDate).endOf("day").toISOString()}`;    const data = await axios.get(queryStr, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return data.data?.docs;
  };
  const exportWord = async () => {
    try {
      const data = await fetchData();
      const arrayTags = data?.filter((p) => p?.tagsInfo && p?.tagsInfo?.length)?.map((p) => p?.tagsInfo?.map((x) => x?.name));
      const mergerTags = mergeDedupe(arrayTags);
      const arrayTagsDocx = mergerTags.map(
        (p) =>
          new Paragraph({
            text: `${p}`,
            numbering: {
              reference: "my-numbering",
              level: 0,
            },
            heading: HeadingLevel.HEADING_2,
            style: "stylePara",
          })
      );
      const tableHeader = new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph("STT")],
            width: {
              size: 10,
              type: WidthType.PERCENTAGE,
            },
          }),
          new TableCell({
            children: [new Paragraph("Ngày đăng")],
            width: {
              size: 10,
              type: WidthType.PERCENTAGE,
            },
          }),
          new TableCell({
            children: [new Paragraph("Kênh đăng")],
            width: {
              size: 20,
              type: WidthType.PERCENTAGE,
            },
          }),
          new TableCell({
            children: [new Paragraph("Đường dẫn")],
            width: {
              size: 20,
              type: WidthType.PERCENTAGE,
            },
          }),
          new TableCell({
            children: [new Paragraph("Nội dung")],
            width: {
              size: 40,
              type: WidthType.PERCENTAGE,
            },
          }),
        ],
      });
      const table = new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        // cantSplit: true,
        rows: [
          tableHeader,
          ...data.map(
            (p, index) =>
              new TableRow({
                children: [
                  new TableCell({
                    children: [new Paragraph((index + 1).toString())],
                    // width: {
                    //   size: 10,
                    //   type: WidthType.PERCENTAGE,
                    // },
                  }),
                  new TableCell({
                    children: [new Paragraph(moment(p.postedAt).format("DD/MM"))],
                    // width: {
                    //   size: 10,
                    //   type: WidthType.PERCENTAGE,
                    // },
                  }),
                  new TableCell({
                    children: [new Paragraph(p?.sourceInfo?.name || "")],
                    // width: {
                    //   size: 20,
                    //   type: WidthType.PERCENTAGE,
                    // },
                  }),
                  new TableCell({
                    children: [new Paragraph(p?.sourceInfo?.link || "")],
                    // width: {
                    //   size: 20,
                    //   type: WidthType.PERCENTAGE,
                    // },
                  }),
                  new TableCell({
                    children: [new Paragraph(p?.textContent || "")],
                    // width: {
                    //   size: 40,
                    //   type: WidthType.PERCENTAGE,
                    // },
                  }),
                ],
              })
          ),
        ],
      });
      const doc = new Document({
        numbering: {
          config: [
            {
              reference: "my-numbering",
              levels: [
                {
                  level: 1,
                  format: LevelFormat.DECIMAL,
                  text: "%1.",
                  alignment: AlignmentType.LEFT,
                  style: {
                    paragraph: {
                      indent: { left: convertInchesToTwip(0.2), hanging: convertInchesToTwip(0.18) },
                    },
                  },
                },
                {
                  level: 3,
                  format: LevelFormat.LOWER_LETTER,
                  text: "%2)",
                  alignment: AlignmentType.LEFT,
                  style: {
                    paragraph: {
                      indent: { left: convertInchesToTwip(0.2), hanging: convertInchesToTwip(0.18) },
                    },
                  },
                },
              ],
            },
          ],
        },
        styles: {
          default: {
            heading1: {
              run: {
                size: 28,
                bold: true,
                color: "000000",
              },
              paragraph: {
                spacing: {
                  after: 120,
                },
              },
            },
            heading2: {
              run: {
                size: 26,
                bold: true,
              },
              paragraph: {
                spacing: {
                  before: 240,
                  after: 120,
                },
              },
            },
          },
          paragraphStyles: [
            {
              id: "stylePara",
              name: "Style Paragraph",
              run: {
                color: "000000",
                size: 26,
                bold: true,
              },
            },
            {
              id: "stylePara1",
              name: "Style Paragraph 1",
              run: {
                color: "000000",
                size: 26,
              },
            },
          ],
        },
        sections: [
          {
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: "BẢN TIN TỔNG HỢP",
                    bold: true,
                    allCaps: true,
                    size: 30,
                  }),
                ],
                heading: HeadingLevel.HEADING_1,
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `(Từ 0h00 ${moment(queryDate).format("DD/M/YYYY")} đến ${moment(queryDate).format("DD/MM/YYYY") == moment(new Date()).format("DD/MM/YYYY") ? `${moment().format("HH")}h${moment().format("mm")}` : "23h59"} ngày ${moment(queryDate).format("DD/M/YYYY")})`,
                    bold: true,
                    size: 28,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    text: `Qua nắm bắt tính hình trên không gian mạng trong khoảng thời gian từ 0h00 ngày ${moment(queryDate).startOf("day").format("DD/M/YYYY")} đến ${
                      moment(queryDate).format("DD/MM/YYYY") == moment(new Date()).format("DD/MM/YYYY") ? `${moment().format("HH")}h${moment().format("mm")}` : "23h59"
                    } ${moment(queryDate).format("DD/M/YYYY")} có một số tin chính đáng chú ý sau:`,
                    size: 28,
                  }),
                ],
                font: "Calibri",
                alignment: AlignmentType.JUSTIFIED,
              }),
              ...arrayTagsDocx,
              // new Paragraph({
              //   text: "Tin liên quan đến QĐ",
              //   numbering: {
              //     reference: "my-numbering",
              //     level: 0,
              //   },
              //   heading: HeadingLevel.HEADING_2,
              //   style: "stylePara",
              // }),
              // new Paragraph({
              //   text: "Tin liên quan đến hoạt động tuyên truyền chống phá QĐ",
              //   numbering: {
              //     reference: "my-numbering",
              //     level: 1,
              //   },
              //   heading: HeadingLevel.HEADING_3,
              //   style: "stylePara1",
              // }),
              // new Paragraph({
              //   text: "Tin khác liên quan đến QĐ",
              //   numbering: {
              //     reference: "my-numbering",
              //     level: 1,
              //   },
              //   heading: HeadingLevel.HEADING_3,
              //   style: "stylePara1",
              // }),
              // new Paragraph({
              //   text: "Tin lộ lọt tài liệu",
              //   numbering: {
              //     reference: "my-numbering",
              //     level: 0,
              //   },
              //   heading: HeadingLevel.HEADING_2,
              //   style: "stylePara",
              // }),
              // new Paragraph({
              //   text: "Tin chú ý khác",
              //   numbering: {
              //     reference: "my-numbering",
              //     level: 0,
              //   },
              //   heading: HeadingLevel.HEADING_2,
              //   style: "stylePara",
              // }),
              // new Paragraph({
              //   text: "Yêu cầu xử lý",
              //   numbering: {
              //     reference: "my-numbering",
              //     level: 0,
              //   },
              //   heading: HeadingLevel.HEADING_2,
              //   style: "stylePara",
              // }),
              // new Paragraph({
              //   text: "(Bản tin chi tiết xem bản trên định dạng PDF) Người tổng hợp: XX",

              //   style: "stylePara1",
              // }),
              table,
            ],
          },
        ],
      });
      addHistory.mutate({ newData: { userId, screen: "Export", description: `Export bản tin ngày - word` }, token });

      Packer.toBlob(doc).then((buffer) => {
        saveAs(buffer, "day.docx");
      });
    } catch (error) {
      console.log(error);
    }
  };
  const exportExcel = async () => {
    let queryStr = `${process.env.REACT_APP_API_URL}/content?process=true&fromDate=${moment(queryDate).startOf("day").toISOString()}&toDate=${moment(queryDate).endOf("day").toISOString()}`;    exportToCSV(queryStr, "day");
  };
  const exportToCSV = async (query, fileName) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const csvData = await axios.get(`${query}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    const dateExport = csvData?.data?.docs?.map((p, index) => ({
      STT: (index + 1).toString(),
      "Tiêu đề": p.title,
      "Kênh đăng": p?.sourceInfo?.name,
      "Ngày đăng": moment(p.postedAt).format("DD/MM/YYYY"),
      "Đường dẫn": p?.sourceInfo?.link,
      "Số người xem": p?.totalReactions,
      "Số lượng người đăng ký kênh": "0",
      "Khoảng thời gian vi phạm": moment(p.postedAt).format("hh:mm"),
      "Nội dung vi phạm(chỉ rõ phút, giây vi phạm cái gì)": p?.violationContent,
      "Vi phạm điều khoản": p?.violationEnactment,
    }));
    var wscols = [{ wch: 6 }, { wch: 20 }, { wch: 20 }, { wch: 15 }, { wch: 40 }, { wch: 15 }, { wch: 25 }, { wch: 20 }, { wch: 40 }, { wch: 40 }];
    const ws = XLSX.utils.json_to_sheet(dateExport);
    ws["!cols"] = wscols;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    addHistory.mutate({ newData: { userId, screen: "Export", description: `Export bản tin ngày - excel` }, token });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const exportToPdf = async () => {
    const data = await fetchData();
    const arrayTags = data?.filter((p) => p?.tagsInfo && p?.tagsInfo?.length)?.map((p) => p?.tagsInfo?.map((x) => x?.name));
    const mergerTags = mergeDedupe(arrayTags);
    const formatData = data?.map((p, i) => ({
      stt: i,
      title: p.title,
      textcontent: p.textContent,
      postedat: moment(p.postedAt).format("DD/MM"),
      sourcename: p?.sourceInfo?.name,
      sourcelink: p?.sourceInfo?.link,
    }));
    addHistory.mutate({ newData: { userId, screen: "Export", description: `Export bản tin ngày - pdf` }, token });
    const blob = await pdf(<PDFfile data={formatData} tags={mergerTags} queryDate={queryDate} />).toBlob();
    saveAs(blob, "day.pdf");
  };
  const items = [
    {
      label: "Word",
      icon: "pi pi-file",
      command: (e) => {
        exportWord();
      },
    },
    {
      label: "Pdf",
      icon: "pi pi-file-pdf",
      command: (e) => {
        exportToPdf();
      },
    },
    {
      label: "Excel",
      icon: "pi pi-file-excel",
      command: (e) => {
        exportExcel();
      },
    },
  ];
  return (
    <>
      <SplitButton label="Xuất" model={items}></SplitButton>
    </>
  );
}
